import React from 'react'
import useResponsive from '@/hooks/responsive'
import { SectionHeader } from '@/v1/@core/section-header'
import { parsedContent } from '@/lib/utils'
import { genLocalImage } from '@/helpers/utils'
import Profucts1 from '~/public/assets/images/homepage/v4/online-invoicing.svg'
import Profucts2 from '~/public/assets/images/homepage/v4/ach-echeck.svg'
import Profucts3 from '~/public/assets/images/homepage/v4/payment-links.svg'
import Profucts4 from '~/public/assets/images/homepage/v4/identity-verification.svg'
import Profucts5 from '~/public/assets/images/homepage/v4/text-to-pay.png'
import Image from 'next/image'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import IconLists from '@/helpers/IconLists'
import Link from 'next/link'

const HomeProducts = () => {
  const text62 = 'lg:text-[62px] text-[28px]'
  const { tabLandscape } = useResponsive()

  const contents = [
    {
      title: 'Online Invoicing',
      description:
        'PayToMe has simplified digital invoicing to get paid faster. Create and send invoices via text and email. Use our streamlined, easy online invoicing to boost cash flow and simplify your account receivable process.',
      thumb: Profucts1,
      url: '/online-invoicing'
    },
    {
      title: 'Payment Links',
      description:
        "Create experience effortless transactions with PayToMe's no-code Payment Links. Instantly create and send secure payment links to your customers via text messaging or email, facilitating smooth, efficient, and safe payments on multiple platforms.",
      thumb: Profucts3,
      url: ''
    },
    {
      title: 'Text-to-Pay',
      description:
        "Enhance your revenue cycle with PayToMe's Patented Text-to-Pay services. Provide convenience by sending a secure payment link directly to customers' phones.",
      thumb: Profucts5,
      url: '/text-to-pay'
    },
    {
      title: 'ACH & E-Check',
      description:
        'Effortlessly process payments with our ACH & E-Check services—fast, low-cost solutions designed for secure, direct bank transfers and improved transaction efficiency.',
      thumb: Profucts2,
      url: ''
    },
    {
      title: 'Identity Verification',
      description:
        "Verify your users and customer identities with PayToMe's comprehensive Know Your Customer (KYC), Know Your Business (KYB), and Bank Verification services. It's reliable for businesses of any size, from one user to one million.",
      thumb: Profucts4,
      url: ''
    }
  ]

  return (
    <>
      <div className='bg-white-50'>
        <div className='container py-[32px] lg:py-[68px]'>
          <SectionHeader
            titlePrefix={parsedContent('Simpler, Easier, Smarter ', text62)}
            title={parsedContent(`Business Products`, text62)}
            isInlineTitle
          />
          {!tabLandscape ? (
            <>
              <div className='flex'>
                {/* First column with two items */}
                <div className='w-1/3 p-4'>
                  <div
                    className={'mb-16 text-center'}
                    data-aos='fade-right'
                    data-aos-delay={500}
                    data-aos-duration={1500}
                  >
                    <div className='mb-2 flex justify-center'>
                      <Image
                        className={'transition-all delay-100 hover:!scale-125'}
                        data-aos='zoom-in'
                        data-aos-delay={500}
                        data-aos-duration={1500}
                        src={genLocalImage(contents[0].thumb).src}
                        alt={contents[0].title}
                      />
                    </div>
                    <h2 className='text-lg font-semibold text-blue-cyan sm:text-[28px]'>{contents[0].title}</h2>
                    <p className='text-base font-normal text-twilight sm:text-lg'>{contents[0].description}</p>

                    {contents[0].url ? (
                      <a
                        href={contents[0].url}
                        className={'ml-1 text-base font-medium text-blue-clear sm:text-xl'}
                        target={'_blank'}
                      >
                        Learn More {'->'}
                      </a>
                    ) : (
                      <span className={'ml-1 cursor-pointer text-base font-medium text-blue-clear sm:text-xl'}>
                        Learn More {'->'}
                      </span>
                    )}
                  </div>

                  <div className={'text-center'} data-aos='fade-right' data-aos-delay={500} data-aos-duration={1500}>
                    <div className='mb-2 flex justify-center'>
                      <Image
                        className={'transition-all delay-100 hover:!scale-125'}
                        data-aos='zoom-in'
                        data-aos-delay={500}
                        data-aos-duration={1500}
                        src={genLocalImage(contents[1].thumb).src}
                        alt={contents[1].title}
                      />
                    </div>
                    <h2 className='text-lg font-semibold text-blue-cyan sm:text-[28px]'>{contents[1].title}</h2>
                    <p className='text-base font-normal text-twilight sm:text-lg'>{contents[1].description}</p>

                    {contents[1].url ? (
                      <a
                        href={contents[1].url}
                        className={'ml-1 text-base font-medium text-blue-clear sm:text-xl'}
                        target={'_blank'}
                      >
                        Learn More {'->'}
                      </a>
                    ) : (
                      <span className={'ml-1 cursor-pointer text-base font-medium text-blue-clear sm:text-xl'}>
                        Learn More {'->'}
                      </span>
                    )}
                  </div>
                </div>

                {/* Second column with one item */}
                <div
                  className='mt-10 flex w-1/3 flex-col justify-center p-4 text-center'
                  data-aos='zoom-in'
                  data-aos-easing='ease-out-cubic'
                  data-aos-delay={500}
                  data-aos-duration={2000}
                >
                  <div className='mb-4 flex w-full justify-center'>
                    <Image
                      className={'transition-all delay-1000 hover:scale-110'}
                      src={genLocalImage(contents[2].thumb).src}
                      alt={contents[2].title}
                    />
                  </div>
                  <h2 className='text-lg font-semibold text-blue-cyan sm:text-[28px]'>{contents[2].title}</h2>
                  <p className='text-base font-normal text-twilight sm:text-lg'>{contents[2].description}</p>

                  {contents[2].url ? (
                    <a
                      href={contents[2].url}
                      className={'ml-1 text-base font-medium text-blue-clear sm:text-xl'}
                      target={'_blank'}
                    >
                      Learn More {'->'}
                    </a>
                  ) : (
                    <span className={'ml-1 cursor-pointer text-base font-medium text-blue-clear sm:text-xl'}>
                      Learn More {'->'}
                    </span>
                  )}
                </div>

                {/* Third column with two items */}
                <div className='w-1/3 p-4'>
                  <div
                    className={'mb-[68px] text-center'}
                    data-aos='fade-left'
                    data-aos-delay={500}
                    data-aos-duration={1500}
                  >
                    <div className='mb-2 flex justify-center'>
                      <Image
                        className={'transition-all delay-100 hover:!scale-125'}
                        data-aos='zoom-in'
                        data-aos-delay={500}
                        data-aos-duration={1500}
                        src={genLocalImage(contents[3].thumb).src}
                        alt={contents[3].title}
                      />
                    </div>
                    <h2 className='text-lg font-semibold text-blue-cyan sm:text-[28px]'>{contents[3].title}</h2>
                    <p className='text-base font-normal text-twilight sm:text-lg'>{contents[3].description}</p>

                    {contents[3].url ? (
                      <a
                        href={contents[3].url}
                        className={'ml-1 text-base font-medium text-blue-clear sm:text-xl'}
                        target={'_blank'}
                      >
                        Learn More {'->'}
                      </a>
                    ) : (
                      <span className={'ml-1 cursor-pointer text-base font-medium text-blue-clear sm:text-xl'}>
                        Learn More {'->'}
                      </span>
                    )}
                  </div>

                  <div className={'text-center'} data-aos='fade-left' data-aos-delay={500} data-aos-duration={1500}>
                    <div className='mb-2 flex justify-center'>
                      <Image
                        className={'transition-all delay-100 hover:!scale-125'}
                        data-aos='zoom-in'
                        data-aos-delay={500}
                        data-aos-duration={1500}
                        src={genLocalImage(contents[4].thumb).src}
                        alt={contents[4].title}
                      />
                    </div>
                    <h2 className='text-lg font-semibold text-blue-cyan sm:text-[28px]'>{contents[4].title}</h2>
                    <p className='text-base font-normal text-twilight sm:text-lg'>{contents[4].description}</p>

                    {contents[4].url ? (
                      <a
                        href={contents[4].url}
                        className={'ml-1 text-base font-medium text-blue-clear sm:text-xl'}
                        target={'_blank'}
                      >
                        Learn More {'->'}
                      </a>
                    ) : (
                      <span className={'ml-1 cursor-pointer text-base font-medium text-blue-clear sm:text-xl'}>
                        Learn More {'->'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={'flex flex-col flex-wrap justify-center gap-4 sm:flex-row'}>
                {contents?.map((content: any, index) => (
                  <div className={'w-[100%] p-2 sm:w-[48%]'} key={index}>
                    <div className='mx-auto mb-2 flex h-[108px] w-[120px] justify-center'>
                      <Image src={genLocalImage(content?.thumb).src} width={120} height={108} alt={content?.title} />
                    </div>
                    <h2 className='text-center text-xl font-semibold text-blue-cyan sm:text-2xl xl:text-start'>
                      {content?.title}
                    </h2>
                    <p className='text-center text-base font-normal text-twilight sm:text-xl xl:text-start'>
                      {content?.description}
                    </p>

                    <Link
                      href={content?.url}
                      className={'mt-3 block flex items-center justify-center font-medium text-blue-clear'}
                    >
                      Learn More <span className='ml-2'>{IconLists.bigRightArrowIcon('#1678FB', '20')}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className='mt-6 flex items-center justify-center sm:mt-[48px]'>
            <CallToActionButton
              className={'sm:ml-[50px]'}
              href={'/pricing'}
              text={'Register Now'}
              icon={IconLists.bigRightArrowIcon()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeProducts
